import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { PackagesComponent } from './components/packages/packages.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { FeaturesComponent } from './components/features/features.component';
import { DatacoverageComponent } from './components/datacoverage/datacoverage.component';
import { InquiryComponent } from './components/inquiry/inquiry.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { TermsandconditionComponent } from './components/termsandcondition/termsandcondition.component';
import { CasedetailComponent } from './components/casedetail/casedetail.component';
import { BareactdetailComponent } from './components/bareactdetail/bareactdetail.component';

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
    data: {
      title: 'LawSuit - The Unique Case Finder - Research Simplified',
      description: "Largest Indian case law database for Online Legal Research, Covers All Indian case laws from Supreme Court, All High Courts of India, All Tribunals & Central - State wise Bare Acts / Legislation."
    }
  },
  {
    path: "about",
    component: AboutComponent
  },  
  {
    path: "contact",
    component: ContactComponent
  },
  {
    path: "registration",
    component: RegistrationComponent
  },
  {
    path: "forgotpassword",
    component: ForgotpasswordComponent
  },
  {
    path: "packages",
    component: PackagesComponent
  },
  {
    path: "articles",
    component: ArticlesComponent
  },
  {
    path: "features",
    component: FeaturesComponent
  }, {
    path: "datacoverage",
    component: DatacoverageComponent
  },
  {
    path: "inquiry",
    component: InquiryComponent
  },
  {
    path: "privacypolicy",
    component: PrivacypolicyComponent
  },
  {
    path: "termsandcondition",
    component: TermsandconditionComponent
  },
  {
    path: "casedetail",
    component: CasedetailComponent,
  },
  {
    path: "bareactdetail",
    component: BareactdetailComponent,
  },
  // {
  //   path: "login/:query",
  //   component: LoginComponent
  // },
  {
    path: 'Lawsuit',
    loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule),
  },
];




@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false, onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
