import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
//headers = headers.set('token', 'true');

@Injectable({
  providedIn: 'root'
})
export class CaselawService {

  
  constructor(
    private httpclient: HttpClient,
    private httpclientwithoutauth: HttpClient,
    private handler: HttpBackend
  ) { 
    this.httpclientwithoutauth = new HttpClient(handler);
  }
  public getTree(userid, flag): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getTree?userid=${userid}&flag=${flag}`, {
      headers
    });
  }
  public addcourtsettigs(userid, courts): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/caselaw/addCourtSettings`, { userid, courts }, {
      headers
    });
  }
  public getFulltext(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getfulltextautocomplete?searchtext=${searchText}`, {
      headers
    });
  }
  public getActs(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getactsautocomplete?searchtext=${searchText}`, {
      headers
    });
  }
  public getCourts(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getcourtsautocomplete?searchtext=${searchText}`, {
      headers
    });
  }
  public getPartyName(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getpartynameautocomplete?searchtext=${searchText}`, {
      headers
    });
  }

  public getSendemailidautocomplete(emailid,userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getsendemailidautocomplete?emailid=${emailid}&userid=${userid}`, {
      headers
    });
  }
  public getRespondentName(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getrespondentsautocomplete?searchtext=${searchText}`, {
      headers
    });
  }
  public getSubject(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getsubjectautocomplete?searchtext=${searchText}`, {
      headers
    });
  }

  public getJudges(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getjudgesautocomplete?searchtext=${searchText}`, {
      headers
    });
  }
  public getAdvocate(searchText): Observable<any> {
    headers
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getadvocateautocomplete?searchtext=${searchText}`, {
    });
  }
  public getCaseType(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getappealtypeateautocomplete?searchtext=${searchText}`, {
      headers
    });
  }
  public getCaseNumber(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getappealnumberateautocomplete?searchtext=${searchText}`, {
      headers
    });
  }
  public getFinalDecision(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getfinaldecisionautocomplete?searchtext=${searchText}`, {
      headers
    });
  }
  public getCitiations(operation, countryMasterID): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getCitiations?operation=${operation}&countryMasterID=${countryMasterID}`, {
      headers
    });
  }
  public getSectionList(searchText): Observable<any> {
    headers
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getsectionlist?title=${searchText}`, {
    });
  }
  public getSectionListTag(searchText,actcode): Observable<any> {
    headers
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getsectionlist?title=${searchText}&actcode=${actcode}`, {
    });
  }
  public getCitiationsData(book, flag, year, vol): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getcitationdata?book=${book.replace('&', '*')}&flag=${flag}&year=${year}&vol=${vol}`, {
      headers
    });
  }
  public getQueryResult(payload, adddocument, userid): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/ec/getResults?adddocument=${adddocument}&userid=${userid}`, payload, {
      headers
    });
  }
  public getsuggestions(payload): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/ec/getSuggestions`, payload, {
      headers
    });
  }
  public saveQuery(payload): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/caselaw/saveQuery`, payload, {
      headers
    });
  }
  public updateQuery(payload): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/caselaw/updateQuery`, payload, {
      headers
    });
  }
  public updateSavedResultQuery(payload): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/caselaw/updatesaveResultQuery`, payload, {
      headers
    });
  }
  public getquerybyparent(parentid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getquerybyparent?parentid=${parentid}`, {
      headers
    });
  }
  public downloadPDF(payload): Observable<any> {
    return this.httpclient.post<any>(`${environment._nodeapiUrl}/api/getPDFbyURL`, payload, {
      responseType: 'blob' as 'json',
      reportProgress: true,
    });
  }
  public sendPDF(payload): Observable<any> {
    return this.httpclient.post<any>(`${environment._nodeapiUrl}/api/getPDFbyURL`, payload);
  }

  public usersendemailid(sendemailid): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/caselaw/addsendemailid`, sendemailid, {
      headers
    });
  }

  public savebookmark(bookmark): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/history/addbookmark`, bookmark, {
      headers
    });
  }
  public getCss(path): Observable<any> {


    return this.httpclient.get<any>(path, {
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers':'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
        'Access-Control-Allow-Methods':'*'
      },
      responseType: 'text' as 'json',
      reportProgress: true,
    });
  }
  public getCaseCitedByFileName(filename): Observable<any> {
    headers
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getCaseCitedByFileName?filename=${filename}`, {
    });
  }
  public getOverruleddata(filename): Observable<any> {
    headers
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getOverruledcasedata?filename=${filename}`, {
    });
  }
  public saveCaseLawResults(resultName, userid, description, typeOfSearch, searchCriteriaId, totalRec, file_name): Observable<any> {
    headers
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/saveResultCaseLaw?resultName=${resultName}&userid=${userid}&description=${description}&typeOfSearch=${typeOfSearch}&searchCriteriaId=${searchCriteriaId}&totalRec=${totalRec}&file_name=${file_name}`, {
    });
  }

  public GetReplicaDocument(docpdfif): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caseview/getreplicadocument?docpdfid=${docpdfif}`, {
      headers
    });
  }

  public gettopsearchhistorydata(userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/history/gettopsearchhistory?userid=${userid}`, {
      headers
    });
  }

  public postChatPDFwithfile(formdata): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', "multipart/form-data");
    return this.httpclient.post<any>(`${environment._apiUrl}/api/ec/postChatPdfWithFile`, formdata, {
      headers
    });
   
  }

  public postMessageWithSourceId(data): Observable<any> {
    // let headers = new HttpHeaders();
        
    // headers.append('Content-Type', "application/json");
    // headers.append('x-api-key', "sec_jpGadzUUbTc0wMV5l8JkTKPLy7PcGr7Y");
    // headers.append('notoken', 'true');
    // headers.append('Access-Control-Allow-Headers','Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'),

    // headers.append('Access-Control-Allow-Origin', "*");
    // headers.append('Access-Control-Allow-Credentials' , 'true');
    // headers.append('Access-Control-Allow-Methods' , 'GET, POST, PATCH, DELETE, PUT, OPTIONS');
    return this.httpclient.post<any>(`${environment._apiUrl}/api/ec/postChatWithSourceID`, data, {
      headers
    });
   
  }
  public CheckAIUserDocumentExist(userid,file_name): Observable<any> {

    return this.httpclient.post<any>(`${environment._apiUrl}/api/ec/CheckAIDocumentExist?file_name=${file_name}&userid=${userid}`,"" , {
      headers
    });
   
  }
  public savePersonalNote(resultName, userid, file_name): Observable<any> {
    headers
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/savePersonalNote?personalNote=${resultName}&userid=${userid}&file_name=${file_name}`, {
    });
  }
  public DeletePersonalNote(userid, file_name): Observable<any> {
    headers
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/DeletePersonalNote?userid=${userid}&file_name=${file_name}`, {
    });
  }
  public GetAISugeestions(): Observable<any> {
    headers
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getaisuggesions`, {
    });
  }
}
