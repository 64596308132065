import { BrowserModule , Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { PackagesComponent } from './components/packages/packages.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { FeaturesComponent } from './components/features/features.component';
import { DatacoverageComponent } from './components/datacoverage/datacoverage.component';
import { InquiryComponent } from './components/inquiry/inquiry.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterceptorService } from './services/interceptor.service';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { FooterComponent } from './components/footer/footer.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { TermsandconditionComponent } from './components/termsandcondition/termsandcondition.component';
import { BotDetectCaptchaModule } from 'angular-captcha';
import { CasedetailComponent } from './components/casedetail/casedetail.component';
import { BareactdetailComponent } from './components/bareactdetail/bareactdetail.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AboutComponent,
    ContactComponent,
    RegistrationComponent,
    ForgotpasswordComponent,
    PackagesComponent,
    ArticlesComponent,
    FeaturesComponent,
    DatacoverageComponent,
    InquiryComponent,
    NavigationComponent,
    FooterComponent,
    PrivacypolicyComponent,
    TermsandconditionComponent,
    CasedetailComponent,
    BareactdetailComponent,
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BotDetectCaptchaModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      countDuplicates: true,
    }),
    
  ],
 
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,  
    },
    Title],
    
  bootstrap: [AppComponent],
})
export class AppModule { }
